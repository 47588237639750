"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function aboutHeroAnimation() {
  var heroImages = document.querySelectorAll('.image-anim img');
  if (heroImages.length !== 0) {
    heroImages.forEach(function (image) {
      image.classList.add('animated');
    });
  }
}
document.addEventListener("DOMContentLoaded", function () {
  var targetId = window.location.hash.substring(1);
  if (targetId) {
    var targetElement = document.getElementById(targetId);
    if (targetElement) {
      // Prevent animations initially to ensure layout is stable
      document.body.classList.add('disable-animations');

      // Scroll to anchor
      setTimeout(function () {
        var elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
        var offsetPosition = elementPosition;
        window.scrollTo({
          top: offsetPosition
        });

        // Re-enable animations after the scroll
        document.body.classList.remove('disable-animations');
      }, 200); // Adjust this delay if necessary to allow the layout to settle
    }
  }
});

var animationsHandler = function animationsHandler() {
  fadeInAnimation();
};
var fadeInAnimation = function fadeInAnimation() {
  var options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1
  };
  var callback = function callback(entries, observer) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        entry.target.classList.add('visible');
        observer.unobserve(entry.target);
      }
    });
  };
  var observer = new IntersectionObserver(callback, options);
  var elements = document.querySelectorAll('.fade-in');
  if (elements.length !== 0) {
    elements.forEach(function (el) {
      observer.observe(el);
    });
  }
};
animationsHandler();
function btnAnimation() {
  if (window.matchMedia("(hover: hover)").matches) {
    var btns = document.querySelectorAll("[data-btn-animated]");
    if (btns.length) {
      btns.forEach(function (btn) {
        var btnText = btn.innerText;
        var btnWords = [];
        if (btnText) {
          btnWords = btnText.split(" ");
        }
        if (btnWords[0].length <= 2) {
          btnWords[1] = btnWords[0] + ' ' + btnWords[1];
          btnWords.shift();
        }
        btn.innerText = "";
        btnWords.forEach(function (word) {
          var span = document.createElement("span");
          span.innerText = word + " ";
          span.setAttribute("class", "word");
          btn.appendChild(span);
        });
      });
    }
  }
}
function bulbAnimation() {
  var options = {
    root: null,
    rootMargin: '-50% 0% -50% 0%',
    threshold: 0
  };
  var observer = new IntersectionObserver(function (entries, observer) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        entry.target.classList.add('glowing');
        observer.unobserve(entry.target);
      }
    });
  }, options);
  var bulbs = document.querySelectorAll('.bulb-animated .bulb.middle');
  if (bulbs.length !== 0) {
    bulbs.forEach(function (bulb) {
      return observer.observe(bulb);
    });
  }
}
function buttonAccordionHandler() {
  var accordionBtns = document.querySelectorAll('[data-accordion-item-btn]');
  if (accordionBtns.length !== 0) {
    accordionBtns.forEach(function (btn) {
      //btn.setAttribute('data-accordion-btn-offset-y', Math.round(document.body.scrollTop + btn.getBoundingClientRect().top));
      btn.addEventListener('click', accordionBtnClickHandler);
    });
  }
}
function accordionBtnClickHandler(event) {
  var accordionClickedButton = event.target.closest('[data-accordion-item-btn]');
  if (!accordionClickedButton) {
    return;
  }
  var accordionClickedItemContent = accordionClickedButton.nextElementSibling;
  if (!accordionClickedItemContent) {
    return;
  }
  var accordionClickedItemSpacer = accordionClickedItemContent.nextElementSibling;
  if (!accordionClickedItemSpacer) {
    return;
  }
  var accordion = accordionClickedButton.closest('[data-multirow-accordion]');
  if (!accordion) {
    return;
  }
  var accordionButtons = accordion.querySelectorAll('[data-accordion-item-btn]');
  accordionButtons.forEach(function (button) {
    if (button !== accordionClickedButton) {
      var accordionItemContent = button.nextElementSibling;
      if (accordionItemContent && accordionItemContent.hasAttribute('data-accordion-item-content')) {
        var accordionItemSpacer = accordionItemContent.nextElementSibling;
        if (accordionItemSpacer) {
          accordionItemSpacer.style.height = 0;
        }
        accordionItemContent.style.opacity = 0;
      }
      button.classList.remove('accordion-content-expanded');
    }
  });
  if (accordionClickedButton.classList.contains('accordion-content-expanded')) {
    accordionClickedButton.classList.remove('accordion-content-expanded');
    accordionClickedItemSpacer.style.height = 0;
    accordionClickedItemContent.style.opacity = 0;
  } else {
    accordionClickedButton.classList.add('accordion-content-expanded');
    accordionClickedItemSpacer.style.height = window.getComputedStyle(accordionClickedItemContent).height;
    accordionClickedItemContent.style.opacity = 1;
  }
  $('html,body').animate({
    scrollTop: $(accordionClickedButton).offset().top - 100
  }, 100);
}
var checkboxFix = function checkboxFix() {
  var checkboxes = document.querySelectorAll('form input[type="checkbox"]');
  if (!checkboxes.length) {
    return;
  }
  checkboxes.forEach(function (checkbox) {
    checkbox.addEventListener('change', function (event) {
      if (event.target.type === 'checkbox') {
        var label = event.target.closest('label');
        if (!label) {
          return;
        }
        if (event.target.checked) {
          label.classList.add('checked');
        } else {
          label.classList.remove('checked');
        }
      }
    });
  });
};
checkboxFix();
// fixing file upload button UI according to required design

function fileUploadBtnUI() {
  var fileGroups = document.querySelectorAll('.formBlock__inputBlock--file');
  var fileButtons = document.querySelectorAll('.btn-select-file');
  if (fileGroups.length !== 0 && fileButtons.length !== 0) {
    fileButtons.forEach(function (btn) {
      btn.addEventListener('click', function () {
        btn.closest('.formBlock__inputBlock--file').querySelector('input[type="file"]').click();
      });
    });
    fileGroups.forEach(function (fileGroup) {
      var inputFile = fileGroup.querySelector('input[type="file"]');
      if (inputFile) {
        inputFile.addEventListener('change', function (event) {
          var fileName = event.target.files[0].name;
          fileGroup.querySelector('.btn-select-file').textContent = 'File: ' + fileName;
        });
      }
    });
  }
}
function formSubmitPopup() {
  // these are taken from CF7 forms' ids

  var POPUPS = {
    '9991': 'partners-popup',
    '9571': 'consult-popup',
    '10539': 'vacancy-popup',
    '9954': 'contacts-popup',
    '9661': 'book-popup',
    '12035': 'poland-form-popup',
    '12912': 'examples-thankyou-popup',
    '13253': 'consult-popup',
    '13282': 'consult-popup',
    '13284': 'consult-popup'
  };
  document.addEventListener('wpcf7mailsent', function (event) {
    var contactFormId = event.detail.contactFormId;
    for (var id in POPUPS) {
      if (parseInt(id) === contactFormId) {
        console.log(contactFormId, POPUPS[id]);
        popupHandler(POPUPS[id]);
      }
    }
  }, false);
}
function popupHandler(popupId) {
  showFormPopup(popupId);
  closeFormPopup(popupId);
}
function showFormPopup(popupId) {
  var formPopup = document.querySelector("#".concat(popupId));
  if (formPopup) {
    formPopup.classList.add('popup-visible');
    document.querySelector('body').classList.add('scroll-lock');
  }
}
function closeFormPopup(popupId) {
  var btnClose = document.querySelector("#".concat(popupId, " .btn-close-popup"));
  var popupWrapper = document.querySelector("#".concat(popupId));
  if (popupWrapper) {
    popupWrapper.addEventListener('click', function (event) {
      if (event.target === btnClose || event.target == popupWrapper) {
        event.target.closest('.popup-wrapper').classList.remove('popup-visible');
        document.querySelector('body').classList.remove('scroll-lock');
      }
    });
  }
}
var formSubmit = function formSubmit() {
  document.addEventListener('wpcf7invalid', highlightInvalidInputs, false);
  document.addEventListener('wpcf7mailsent', resetFormStyling, false);
  document.addEventListener('wpcf7mailsent', closeAvailablePopup, false);
  document.addEventListener('wpcf7mailsent', addFormNameToDataLayer, false);
  resetInvalidTextInputsOnFocus();
  resetInvalidCheckboxesOnChange();
  filterSpamSubmit();
};
var highlightInvalidInputs = function highlightInvalidInputs(event) {
  var invalidForm = event.target;

  /* console.log(event.detail.inputs);
    event.detail.inputs.forEach(input => {
    //excluding inputs with UTM/ref/etc. and leave only user inputs
    const regex = /_wpcf7|utm_|ref|gid/g;
    const found = input.name.match(regex);
    let inputHTMLTag = invalidForm.querySelector(`input[name="${input.name}"], textarea, input[type="url"]`);
      let emailIsCorrect = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(input.value);
      if(!found && (input.value === '' || !emailIsCorrect )){
      if(inputHTMLTag && inputHTMLTag.hasAttribute('aria-required', 'true')){
        inputHTMLTag.closest('.wpcf7-form-control-wrap').classList.add('invalid');
      }
    }else{
      inputHTMLTag.closest('.wpcf7-form-control-wrap').classList.add('valid');
    }
  }); */

  // checkbox inputs are not returned with event.detail.inputs
  // look for them as usual 

  var checkboxes = invalidForm.querySelectorAll('[type="checkbox"]');
  if (checkboxes.length !== 0) {
    checkboxes.forEach(function (checkbox) {
      var checkboxWrapper = checkbox.closest('.formBlock__inputBlock--checkbox');
      if (checkboxWrapper && !checkbox.checked) {
        //highlighting checkboxes
        checkboxWrapper.classList.add('invalid');
      }
    });
  }
};
var resetFormStyling = function resetFormStyling(event) {
  var form = event.target;
  //resetTextInputs(form);
  resetCustomCheckboxes(form);
};
var resetTextInputs = function resetTextInputs(form) {
  var inputs = form.querySelectorAll('input, textarea');
  if (!inputs.length) {
    return;
  }
  inputs.forEach(function (input) {
    var inputWrapper = input.closest('.wpcf7-form-control-wrap');
    if (inputWrapper) {
      inputWrapper.classList.remove('valid');
    }
  });
};
var resetInvalidTextInputsOnFocus = function resetInvalidTextInputsOnFocus() {
  var inputs = document.querySelectorAll('.formBlock input');
  if (!inputs.length) {
    return;
  }
  inputs.forEach(function (input) {
    input.addEventListener('focus', function (event) {
      var inputWrapper = event.target.closest('.wpcf7-form-control-wrap');
      if (inputWrapper) {
        inputWrapper.classList.remove('invalid');
      }
    });
  });
};
var resetCustomCheckboxes = function resetCustomCheckboxes(form) {
  var checkboxLabels = form.querySelectorAll('.formBlock__checkbox label');
  if (!checkboxLabels.length) {
    return;
  }
  checkboxLabels.forEach(function (label) {
    label.classList.remove('checked');
  });
};
var resetInvalidCheckboxesOnChange = function resetInvalidCheckboxesOnChange() {
  var checkboxes = document.querySelectorAll('.formBlock [type="checkbox"]');
  if (!checkboxes.length) {
    return;
  }
  checkboxes.forEach(function (checkbox) {
    checkbox.addEventListener('change', function (event) {
      var checkboxWrapper = event.target.closest('.formBlock__inputBlock--checkbox');
      if (!checkboxWrapper) {
        return;
      }
      if (checkboxWrapper.classList.contains('invalid')) {
        checkboxWrapper.classList.remove('invalid');
      }
    });
  });
};
var filterSpamSubmit = function filterSpamSubmit() {
  var submitBtns = document.querySelectorAll('form input[type="submit"]');
  if (submitBtns.length === 0) {
    return;
  }
  submitBtns.forEach(function (submitBtn) {
    //submitBtn.addEventListener('click', checkName);
    submitBtn.addEventListener('click', checkUTMInputs);
    //submitBtn.addEventListener('click', checkEmail);
  });
};

var checkName = function checkName(event) {
  var currentForm = event.target.closest('form');
  var nameInputs = currentForm.querySelectorAll('input[name*="name"]');
  nameInputs.forEach(function (input) {
    if (/u[^\w\s]|\d/.test(input.value)) {
      event.preventDefault();
      console.log('spam error');
    }
  });
};
var checkUTMInputs = function checkUTMInputs(event) {
  var currentForm = event.target.closest('form');
  var utmInputs = currentForm.querySelectorAll('input[name^="utm"]');
  var hasAtSymbol = false;
  utmInputs.forEach(function (input) {
    if (input.value.indexOf('@') !== -1) {
      hasAtSymbol = true;
    }
  });
  if (hasAtSymbol) {
    event.preventDefault();
    console.log('spam error');
  }
};
var checkEmail = function checkEmail(event) {
  var currentForm = event.target.closest('form');
  var emailInput = currentForm.querySelector('input[type="email"]');
  if (emailInput.value === 'testing@example.com') {
    event.preventDefault();
    console.log('spam error');
  }
};
var closeAvailablePopup = function closeAvailablePopup(event) {
  var form = event.target;
  var popup = form.closest('.popup-wrapper');
  if (popup) {
    popup.classList.remove('popup-visible');
    document.body.classList.remove('scroll-lock');
  }
};
var addFormNameToDataLayer = function addFormNameToDataLayer(event) {
  var formName = '';
  event.detail.inputs.forEach(function (input) {
    if (input.name === 'form-name') {
      formName = input.value;
    }
  });
  dataLayer.push({
    'event': 'lead_form',
    'form_name': formName // Передаємо назву форми
  });
};

function header() {
  addIconsToMenuItems();
  menuDropdownsHandler();
  mobileMenuToggle();
  mobileMenuHandler();
  languageListHandler();
  contactButtonPlHandler(); // contact button on Poland landing
}

function contactButtonPlHandler() {
  var contactButton = document.querySelector('.contact-button-pl-mob');
  if (!contactButton) {
    return;
  }
  contactButton.addEventListener('click', function () {
    var mobMenuToggle = document.querySelector('#mobile-menu-toggle');
    var headerMobNav = document.querySelector('.header-mob-nav');
    var body = document.querySelector('body');
    mobMenuToggle.checked = false;
    headerMobNav.classList.remove('visible');
    body.classList.remove('scroll-lock');
  });
}
function menuDropdownsHandler() {
  var dropdownItems = document.querySelectorAll('.header-dt-menu .menu-item');
  var firstLevelMenuItems = document.querySelectorAll('.header-dt-menu > .menu-item');
  if (dropdownItems.length !== 0) {
    dropdownItems.forEach(function (dropdown) {
      dropdown.addEventListener('mouseenter', openSubMenu);
    });
  }
  if (firstLevelMenuItems.length !== 0) {
    firstLevelMenuItems.forEach(function (menuItem) {
      menuItem.addEventListener('mouseleave', closeSubMenu);
    });
  }
}
function openSubMenu(event) {
  var subMenu = event.target.querySelector('.sub-menu');
  if (subMenu) {
    subMenu.classList.add('visible');
  }
  event.stopPropagation();
}
function closeSubMenu(event) {
  var subMenus = event.target.querySelectorAll('.sub-menu');
  if (subMenus.length !== 0) {
    subMenus.forEach(function (subMenu) {
      subMenu.classList.remove('visible');
    });
  }
  event.stopPropagation();
}
function mobileMenuToggle() {
  var mobMenuToggle = document.querySelector('.mobile-menu-toggle');
  var headerMobNav = document.querySelector('.header-mob-nav');
  var body = document.querySelector('body');

  // this element causes overflow:hidden failure on BODY tag, 
  // so need to hide it when mobile menu is opened
  var backgroundLogo = document.querySelector('[data-header-bg-logo]');
  if (!mobMenuToggle || !headerMobNav) {
    return;
  }
  var mobMenuItems = headerMobNav.querySelectorAll('.menu-item > a');
  mobMenuToggle.addEventListener('click', function (event) {
    if (event.target.checked === true) {
      headerMobNav.classList.add('visible');
      body.classList.add('scroll-lock');
      if (backgroundLogo) {
        backgroundLogo.style.display = 'none';
      }
    } else {
      headerMobNav.classList.remove('visible');
      body.classList.remove('scroll-lock');
      if (backgroundLogo) {
        backgroundLogo.style.display = 'block';
      }
    }
    event.stopPropagation();
  });
  if (mobMenuItems.length) {
    mobMenuItems.forEach(function (menuItem) {
      menuItem.addEventListener('click', function (event) {
        if (event.target.tagName !== 'A') {
          return;
        }
        var href = event.target.getAttribute('href');
        if (!href) {
          return;
        }
        if (href.indexOf('#') !== -1) {
          headerMobNav.classList.remove('visible');
          body.classList.remove('scroll-lock');
          mobMenuToggle.querySelector('input[type="checkbox"]').checked = false;
        }
      });
    });
  }
}
function addIconsToMenuItems() {
  var arrowIconNode = document.querySelector('.menu-item-icon-arrow');
  var plusIconNode = document.querySelector('.menu-item-icon-plus');
  var menuItems = document.querySelectorAll('.sub-menu .menu-item > a');
  if (menuItems.length !== 0) {
    menuItems.forEach(function (menuItem) {
      if (menuItem.closest('.menu-item').classList.contains('menu-item-has-children')) {
        if (plusIconNode) {
          var clone = plusIconNode.cloneNode(true);
          menuItem.appendChild(clone);
        }
      } else {
        if (arrowIconNode) {
          var _clone = arrowIconNode.cloneNode(true);
          menuItem.appendChild(_clone);
        }
      }
    });
  }
}
function mobileMenuHandler() {
  var dropdownItems = document.querySelectorAll('.header-mob-menu .menu-item-has-children');
  if (dropdownItems.length !== 0) {
    dropdownItems.forEach(function (dropdownItem) {
      dropdownItem.addEventListener('click', function (event) {
        //event.preventDefault();
        var parentMenuItem = event.target.closest('.menu-item-has-children');
        var subMenu = parentMenuItem.querySelector('.sub-menu');
        if (subMenu) {
          subMenu.classList.toggle('visible');
          parentMenuItem.classList.toggle('sub-menu-is-visible');
        }
        event.stopPropagation();
      });
    });
  }
}
function toggleLangLIst() {
  var langNav = document.querySelector('[data-lang-nav]');
  if (!langNav) {
    return;
  }
  var langToggle = langNav.querySelector('[data-lang-toggle]');
  var langList = langNav.querySelector('[data-lang-list]');
  if (!langToggle || !langList) {
    return;
  }
  langToggle.addEventListener('click', function () {
    langToggle.classList.toggle('active');
    langList.classList.toggle('expanded');
  });
}
function setCurrentLangItemVisible() {
  var currentLangWrapperTag = document.querySelector('[data-lang-current]');
  if (!currentLangWrapperTag) {
    return;
  }
  var pllCurrentLangItem = document.querySelector('.lang-list .current-lang > a');
  if (!pllCurrentLangItem) {
    return;
  }
  currentLangWrapperTag.textContent = pllCurrentLangItem.textContent;
  pllCurrentLangItem.closest('.current-lang').style.display = 'none';
}
function languageListHandler() {
  toggleLangLIst();
  setCurrentLangItemVisible();
}
function homeHeroAnimation() {
  if (!window.matchMedia("(min-width: 1025px)").matches) {
    var home = document.querySelector(".home");
    if (!home) {
      return;
    }
    var preAnimatedElements = home.querySelectorAll(".pre-animation");
    if (preAnimatedElements.length === 0) {
      return;
    }
    preAnimatedElements.forEach(function (el) {
      el.classList.remove("pre-animation");
    });
    return;
  }
  var typeLines = document.querySelectorAll(".type-line");
  if (typeLines.length !== 0) {
    typeLines.forEach(function (typeLine) {
      typeLine.classList.add("animated");
    });
  }
  if (typeLines[1]) {
    typeLines[1].addEventListener("animationend", startMainAnimation);
  }
}
function startMainAnimation() {
  var HIDE_CLASS = "pre-animation";
  var ANIMATED_CLASS = "animated";
  var animatedArrows = document.querySelectorAll(".animate-arrow");
  var animatedCubes = document.querySelectorAll(".cubes-container .cube");
  var hiddenElements = document.querySelectorAll(" .home .site-header, .home .animation-container, .home .hero-content-bottom, .home .hero-footer, .home .section-cases, .home .lang");
  if (hiddenElements.length !== 0) {
    hiddenElements.forEach(function (el) {
      el.classList.remove(HIDE_CLASS);
    });
  }
  if (animatedArrows.length !== 0 || animatedCubes.length !== 0) {
    [].concat(_toConsumableArray(animatedArrows), _toConsumableArray(animatedCubes)).forEach(function (el) {
      el.classList.add(ANIMATED_CLASS);
    });
  }
}
var hideFooterAd = function hideFooterAd() {
  var adElement = document.querySelector('a[href="https://www.s-sols.com/products/wordpress/accelerator?utm_source=usersite&utm_medium=banner&utm_campaign=free_lim_ver&utm_term=accel"]');
  console.log(adElement);
  if (adElement) {
    adElement.setAttribute('style', 'display:none!important;');
  }
};
hideFooterAd();
function historyBack() {
  var backBtn = document.querySelector('a[href="#back"]');
  if (backBtn) {
    backBtn.addEventListener('click', function () {
      history.back();
    });
  }
}
var horizontalScrollHandler = function horizontalScrollHandler() {
  var scroll = document.querySelector('.horizontal-scroll__inner');
  var documentHeight = document.documentElement.scrollHeight;
  if (!scroll) {
    return;
  }
  document.addEventListener('scroll', function () {
    scroll.style.width = "".concat(document.documentElement.scrollTop * 100 / (document.documentElement.scrollHeight - document.documentElement.clientHeight), "%");
  });
};
horizontalScrollHandler();
function loader() {
  var preloader = document.getElementById('preloader');
  if (!preloader) {
    return;
  }
  preloader.classList.add('hide-preloader');
  setInterval(function () {
    preloader.classList.add('preloader-hidden');
  }, 200);
}
var reviewsGalleryHandler = function reviewsGalleryHandler() {
  var togglers = document.querySelectorAll('[data-review-toggle]');
  if (togglers.length === 0) {
    return;
  }
  togglers.forEach(function (toggle) {
    toggle.addEventListener('click', showReviewsModalGallery);
  });
  var btnClose = document.querySelector('[data-gallery-container] .modal-gallery-container .btn-close');
  if (!btnClose) {
    return;
  }
  btnClose.addEventListener('click', hideReviewsModalGallery);
};
var showReviewsModalGallery = function showReviewsModalGallery(event) {
  var modalGallery = event.target.closest('[data-gallery-container]').querySelector('.modal-gallery-container');
  var currentSlideIndex = 0;
  if (!modalGallery) {
    return;
  }
  if (event.target.closest('[data-review-toggle]')) {
    modalGallery.classList.add('visible');
    document.querySelector('body').classList.add('scroll-lock');

    //set current slide
    currentSlideIndex = event.target.closest('.slick-slide').dataset.slickIndex;
    $('[data-modal-gallery]').slick('slickGoTo', currentSlideIndex);
  }
};
var hideReviewsModalGallery = function hideReviewsModalGallery(event) {
  var modalGallery = event.target.closest('[data-gallery-container]').querySelector('.modal-gallery-container');
  if (!modalGallery) {
    return;
  }
  modalGallery.classList.remove('visible');
  document.querySelector('body').classList.remove('scroll-lock');
};
var modalGallerySlider = function modalGallerySlider() {
  var slider = document.querySelector("[data-modal-gallery]");
  if (!slider) {
    return;
  }
  var clients = slider.querySelectorAll('.client-slide');
  if (clients.length > 1) {
    var slickOptions = {
      dots: false,
      slidesToShow: 1,
      infinite: true,
      arrows: false,
      variableWidth: false,
      adaptiveHeight: false,
      fade: true,
      cssEase: 'linear',
      responsive: [{
        breakpoint: 1025,
        settings: {
          dots: true
        }
      }]
    };
    $(slider).slick(slickOptions);
    $("[data-modal-gallery-nav] .slide-prev").click(function (e) {
      $(slider).slick("slickPrev");
    });
    $("[data-modal-gallery-nav] .slide-next").click(function (e) {
      $(slider).slick("slickNext");
    });
  }
};
var clientsSlider = function clientsSlider() {
  var SLIDER_BREAKPOINT = 769;
  var NAV_BREAKPOINT = 1025;
  var clientsSlider = document.querySelector("[data-clients-slider]");
  if (!clientsSlider) {
    return;
  }
  var clients = clientsSlider.querySelectorAll('.client-slide');
  var slickOptions = {
    centerMode: false,
    slidesToShow: 2,
    initialSlide: 0,
    infinite: true,
    arrows: false,
    variableWidth: false,
    adaptiveHeight: true,
    responsive: [{
      breakpoint: SLIDER_BREAKPOINT,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
        dots: true
      }
    }]
  };
  if (clients.length > 2 || clients.length > 1 && window.matchMedia("(max-width: ".concat(SLIDER_BREAKPOINT, "px)")).matches) {
    $(clientsSlider).slick(slickOptions);
    var clientsNav = document.querySelector('[data-clients-nav]');
    if (clientsNav && window.matchMedia("(min-width: ".concat(NAV_BREAKPOINT, "px)")).matches) {
      clientsNav.style.display = 'block';
    }
  }
  $(".clients-nav .slide-prev").click(function (e) {
    $(clientsSlider).slick("slickPrev");
  });
  $(".clients-nav .slide-next").click(function (e) {
    $(clientsSlider).slick("slickNext");
  });
};
clientsSlider();
modalGallerySlider();
var officesTabs = function officesTabs() {
  var tabNav = document.querySelector('.offices-nav');
  var tabs = document.querySelectorAll('.offices-tab-wrapper');
  if (!tabNav || !tabs.length) {
    return;
  }
  tabNav.addEventListener('click', function (event) {
    var btnId = event.target.dataset.id;
    if (!btnId) {
      return;
    }
    var btns = tabNav.querySelectorAll('button');
    btns.forEach(function (btn) {
      if (btn.dataset.id === btnId) {
        btn.classList.add('active');
      } else {
        btn.classList.remove('active');
      }
    });
    tabs.forEach(function (tab) {
      if (tab.dataset.target === btnId) {
        tab.classList.add('active');
      } else {
        tab.classList.remove('active');
      }
    });
  });
};
officesTabs();
var POPUP_WAS_DISPLAYED = false;
var initObserver = function initObserver() {
  var observableSection = document.querySelector("[data-observable-section]");
  var targetSection = document.querySelector("[data-observable-target]");
  if (!observableSection || !targetSection) {
    return;
  }
  var options = {
    root: null,
    rootMargin: "-50% 0% -50% 0%",
    threshold: 0
  };
  var handleIntersect = function handleIntersect(entries, observer) {
    entries.forEach(function (entry) {
      if (entry.boundingClientRect.top > 0 && !POPUP_WAS_DISPLAYED) {
        if (entry.isIntersecting) {
          targetSection.classList.add("popup-visible");
          document.querySelector("body").classList.add("scroll-lock");
        }
      }
    });
  };
  var observer = new IntersectionObserver(handleIntersect, options);
  observer.observe(observableSection);
};

/* const closeExamplesPopup = () => {
  const closeBtn = document.querySelectorAll("[data-close-examples]");

  if (!closeBtn.length) {
    return;
  }

  closeBtn.forEach(btn => {
    btn.addEventListener("click", (event) => {
      const examplesPopup = document.querySelector("#examples-popup");
      const thankyouPopup = document.querySelector("#examples-thankyou-popup");
  
      if (thankyouPopup) {
        thankyouPopup.classList.remove("popup-visible");
      }
  
      if (examplesPopup) {
        examplesPopup.classList.remove("popup-visible");
      }
      
      POPUP_WAS_DISPLAYED = true;
      document.querySelector("body").classList.remove("scroll-lock");
    });
  })
}; */

var closeExamplesPopup = function closeExamplesPopup() {
  var closeBtn = document.querySelectorAll("[data-close-examples]");
  if (!closeBtn.length) {
    return;
  }
  closeBtn.forEach(function (btn) {
    btn.addEventListener("click", function (event) {
      var popup = event.target.closest('.popup-wrapper');
      if (popup) {
        popup.classList.remove("popup-visible");
      }
      POPUP_WAS_DISPLAYED = true;
      document.querySelector("body").classList.remove("scroll-lock");
    });
  });
};
var onScrollPopupHandler = function onScrollPopupHandler() {
  initObserver();
  closeExamplesPopup();
};
var pageLeadgen = function pageLeadgen() {
  scaleAnimate();
  bulbAnimate();
  bulbAnimatePositioning();
  leadgenCasesSlider();
};
var bulbAnimate = function bulbAnimate() {
  var togglers = document.querySelectorAll('.toggler-block__item input[type="checkbox"]');
  if (!togglers.length) {
    return;
  }
  var togglersBlock = togglers[0].closest('.togglers-block__animation-wrapper');
  if (!togglersBlock) {
    return;
  }
  var bulbLightDt = togglersBlock.querySelector('.togglers-block__animation-target.dt .togglers-block__animation-light');
  var bulbLightMob = togglersBlock.querySelector('.togglers-block__animation-target.mob .togglers-block__animation-light');
  if (!bulbLightDt /* || !bulbLightMob */) {
    return;
  }
  togglers.forEach(function (toggler) {
    toggler.addEventListener('change', function () {
      var checkedCount = 0;
      togglers.forEach(function (toggler) {
        if (toggler.checked) {
          checkedCount++;
        }
      });
      bulbLightDt.style.transform = "translateX(-50%) scale(".concat(checkedCount * 0.25);
      bulbLightMob.style.transform = "translateX(-50%) scale(".concat(checkedCount * 0.25);
    });
  });
};
var scaleAnimate = function scaleAnimate() {
  var checkboxes = document.querySelectorAll('[data-scale-checkbox]');
  var tube = document.querySelector('.scale-block__tube');
  if (!checkboxes.length || !tube) {
    return;
  }
  var tubeHeight = parseInt(window.getComputedStyle(tube).height);
  var imageHTMLTag = document.querySelector('.scale-block__emoji > div');
  checkboxes.forEach(function (checkbox) {
    checkbox.addEventListener('change', function () {
      var checkedCount = 0;
      checkboxes.forEach(function (checkbox) {
        if (checkbox.checked) {
          ++checkedCount;
        }
      });
      if (checkedCount !== 0) {
        tube.style.backgroundPositionY = "".concat(tubeHeight * (1 - checkedCount / checkboxes.length), "px");
        if (checkedCount === 3) {
          if (window.matchMedia('(max-width: 576px)').matches) {
            imageHTMLTag.style.bottom = "".concat(tubeHeight * checkedCount / checkboxes.length, "px");
          } else {
            imageHTMLTag.style.bottom = "".concat(tubeHeight * checkedCount / checkboxes.length - 54, "px");
          }
        } else {
          if (window.matchMedia('(max-width: 576px)').matches) {
            imageHTMLTag.style.bottom = "".concat(tubeHeight * checkedCount / checkboxes.length, "px");
          } else {
            imageHTMLTag.style.bottom = "".concat(tubeHeight * checkedCount / checkboxes.length - 34, "px");
          }
        }
      } else {
        tube.style.backgroundPositionY = '500px';
        if (window.matchMedia('(max-width: 576px)').matches) {
          imageHTMLTag.style.bottom = '0px';
        } else {
          imageHTMLTag.style.bottom = '0px';
        }
      }
      var classList = imageHTMLTag.classList;
      while (classList.length > 0) {
        classList.remove(classList.item(0));
      }
      if (!window.matchMedia('(max-width: 576px)').matches) {
        imageHTMLTag.classList.add("emoji-dt-img-".concat(checkedCount));
      } else {
        imageHTMLTag.classList.add("emoji-mob-img-".concat(checkedCount));
      }
    });
  });
};
var leadgenCasesSlider = function leadgenCasesSlider() {
  var slider = document.querySelector('.leadgen-cases__slider');
  if (!slider) {
    return;
  }
  var currentSlideNumber = document.querySelector(".leadgen-cases__slider-nav .slide-current");
  var totalSlidesNumber = document.querySelector(".leadgen-cases__slider-nav .slide-total");
  $(slider).on("init reInit afterChange", function (event, slick, currentSlide, nextSlide) {
    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    var i = (currentSlide ? currentSlide : 0) + 1;
    currentSlideNumber.innerText = "0".concat(i, " ");
    totalSlidesNumber.innerText = " / 0".concat(slick.slideCount);
  });
  var settings = {
    arrows: false,
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    adaptiveHeight: false,
    variableWidth: true,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        variableWidth: false
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        variableWidth: false,
        adaptiveHeight: true
      }
    }]
  };
  $(slider).slick(settings);
  $(".leadgen-cases__slider-nav .slider-2-col__slide-prev").click(function (e) {
    $(slider).slick("slickPrev");
  });
  $(".leadgen-cases__slider-nav .slider-2-col__slide-next").click(function (e) {
    $(slider).slick("slickNext");
  });
};
var bulbAnimatePositioning = function bulbAnimatePositioning() {
  if (!window.matchMedia("(max-width: 768px)")) {
    return;
  }
  var target = document.querySelector('.toggler-block__item:first-child');
  var animationBlock = document.querySelector('.togglers-block__animation-target.mob');
  if (!target || !animationBlock) {
    return;
  }
  var vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  window.addEventListener('scroll', function () {
    var targetTop = target.getBoundingClientRect().top;
    if (targetTop < vh / 2.5) {
      animationBlock.classList.remove('hide-sticky');
      animationBlock.classList.add('sticky');
    } else {
      animationBlock.classList.remove('sticky');
      animationBlock.classList.add('hide-sticky');
    }
  });
};
pageLeadgen();
var pageMarketingDep = function pageMarketingDep() {
  startVideo();
  mdCasesSlider();
};
var startVideo = function startVideo() {
  var videoBtn = document.querySelector('.page-marketing-dep section.hero .video-container__button');
  var videoOverlay = document.querySelector('.page-marketing-dep section.hero .video-container__overlay');
  var video = document.querySelector('.page-marketing-dep section.hero video');
  if (!videoBtn || !video) {
    return;
  }
  videoBtn.addEventListener('click', function (event) {
    videoOverlay.style.display = 'none';
    video.play();
    video.setAttribute('controls', 'controls');
  });
};
var mdCasesSlider = function mdCasesSlider() {
  if (!window.matchMedia("(max-width: 768px)").matches) {
    return;
  }
  var slider = document.querySelector('.md-cases__slider');
  if (!slider) {
    return;
  }
  var currentSlideNumber = document.querySelector(".md-cases__slider-nav .slide-current");
  var totalSlidesNumber = document.querySelector(".md-cases__slider-nav .slide-total");
  $(slider).on("init reInit afterChange", function (event, slick, currentSlide, nextSlide) {
    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    var i = (currentSlide ? currentSlide : 0) + 1;
    currentSlideNumber.innerText = "0".concat(i, " ");
    totalSlidesNumber.innerText = " / 0".concat(slick.slideCount);
  });
  var settings = {
    arrows: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    adaptiveHeight: true,
    variableWidth: false
  };
  $(slider).slick(settings);
  $(".md-cases__slider-nav .slider-2-col__slide-prev").click(function (e) {
    $(slider).slick("slickPrev");
  });
  $(".md-cases__slider-nav .slider-2-col__slide-next").click(function (e) {
    $(slider).slick("slickNext");
  });
};
pageMarketingDep();
var strategyPage = function strategyPage() {
  twoColumnSliders();
  strategyArticlesSlider();
  toggleBtnClick();
  showMoreCardsClick();
  tabsHabdler();
  togglePopup();
  adjustAccordionYPos();
};
var twoColumnSliders = function twoColumnSliders() {
  var sliders = document.querySelectorAll('.slider-2-col');
  if (!sliders.length) {
    return;
  }
  sliders.forEach(function (slider) {
    var settings = {
      arrows: false,
      dots: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 0,
      infinite: true,
      autoplay: false,
      cssEase: 'linear',
      speed: 750,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }]
    };
    $(slider).slick(settings);
    var prevBtn = slider.nextElementSibling.querySelector('.slider-2-col__slide-prev');
    var nextBtn = slider.nextElementSibling.querySelector('.slider-2-col__slide-next');
    $(prevBtn).click(function (e) {
      $(slider).slick("slickPrev");
    });
    $(nextBtn).click(function (e) {
      $(slider).slick("slickNext");
    });
  });
};
var toggleBtnClick = function toggleBtnClick() {
  var toggleBtn = document.querySelector('.btn-toggle');
  if (!toggleBtn) {
    return;
  }
  toggleBtn.addEventListener('click', function (event) {
    var btn = event.target.closest('.btn-toggle');
    var toggleTargetId = btn.dataset.toggleTarget;
    if (!toggleTargetId) {
      return;
    }
    var toggleTarget = document.getElementById(toggleTargetId);
    if (toggleTarget) {
      toggleTarget.classList.toggle('expanded');
    }
    btn.classList.toggle('active');
    if (btn.dataset.toggleCaption) {
      var textNode = Array.from(btn.childNodes).find(function (node) {
        return node.nodeType === Node.TEXT_NODE;
      });
      if (textNode) {
        var btnCaption = textNode.textContent.trim();
        textNode.textContent = btn.dataset.toggleCaption.trim();
        btn.dataset.toggleCaption = btnCaption;
      }
    }
  });
};
var showMoreCardsClick = function showMoreCardsClick() {
  var toggler = document.querySelector('[data-list-toggle]');
  if (!toggler) {
    return;
  }
  toggler.addEventListener('click', function (event) {
    var cardList = event.target.previousElementSibling;
    if (!cardList && !cardList.classList.contains('card-list')) {
      return;
    }
    var cards = cardList.querySelectorAll('.card:nth-child(n + 4)');
    if (cards.length > 0) {
      cards.forEach(function (card) {
        card.style.display = 'flex';
      });
    }
    event.target.style.display = 'none';
  });
};
var tabsHabdler = function tabsHabdler() {
  var tabBtns = document.querySelectorAll('.tabs-accordion .tab-btn');
  var contents = document.querySelectorAll('.tabs-accordion .content');
  if (!tabBtns.length || !contents.length) {
    return;
  }
  tabBtns.forEach(function (btn) {
    btn.addEventListener('click', function (event) {
      tabBtns.forEach(function (btn) {
        return btn.classList.remove('active');
      });
      btn.classList.add('active');
      var contentId = btn.getAttribute('content-id');
      if (!contentId) {
        return;
      }
      var contentSelected = document.getElementById(contentId);
      contents.forEach(function (content) {
        content.classList.remove('visible');
        var contentCheckbox = content.querySelector('.tab-content-radio');
        if (contentCheckbox) {
          contentCheckbox.checked = false;
        }
      });
      contentSelected.classList.add('visible');
      var contentCheckbox = contentSelected.querySelector('.tab-content-radio');
      if (contentCheckbox) {
        contentCheckbox.checked = true;
      }
    });
  });
};
var togglePopup = function togglePopup() {
  var togglers = document.querySelectorAll('[data-target-id]');
  if (togglers.length === 0) {
    return;
  }
  togglers.forEach(function (toggle) {
    toggle.addEventListener('click', function (event) {
      var clickTarget = event.target.closest('[data-target-id]');
      var targetId = clickTarget.dataset.targetId;
      if (!targetId) {
        return;
      }
      var toggleTarget = document.querySelector("[data-target=\"".concat(targetId, "\"]"));
      if (toggleTarget) {
        toggleTarget.classList.add('popup-visible');
      }
    });
  });
};
var adjustAccordionYPos = function adjustAccordionYPos() {
  var accordionsWithRadio = document.querySelectorAll('[data-accordion-radio-list]');
  if (!accordionsWithRadio.length) {
    return;
  }
  accordionsWithRadio.forEach(function (accordion) {
    var accordionRadios = accordion.querySelectorAll(':scope > [data-accordion-radio-item] > input[type="radio"]');
    if (!accordionRadios.length) {
      return;
    }
    accordionRadios.forEach(function (radio) {
      $(radio).on('click', function () {
        $('html,body').animate({
          scrollTop: $(accordionRadios[0]).offset().top - 100
        }, 300);
      });
    });
  });
};
var strategyArticlesSlider = function strategyArticlesSlider() {
  if (!window.matchMedia('(max-width: 576px)').matches) {
    return;
  }
  var articlesSlider = document.querySelector('[data-strategy-articles-slider]');
  if (!articlesSlider) {
    return;
  }
  var options = {
    slidesToShow: 1,
    infinite: true,
    arrows: false,
    dots: true
  };
  $(articlesSlider).slick(options);
};
strategyPage();
function phoneInputMaskPL() {
  var main = document.querySelector('.main-pl');
  if (!main) {
    return;
  }
  $('.wpcf7-form-control.wpcf7-text.wpcf7-tel ').inputmask("99 999 99 99");
}
function setPolicyUrl() {
  var policyLabels = document.querySelectorAll('.formBlock__submit .checkbox-label a');
  var urlElement = document.querySelector('[data-policy-url]');
  var url;
  if (policyLabels.length === 0 || !urlElement) {
    return;
  }
  url = urlElement.dataset.policyUrl;
  if (!url) {
    return;
  }
  policyLabels.forEach(function (label) {
    label.setAttribute('href', url);
  });
}
function postContentDecor() {
  // otherwise this function causes immense memory leak!!!
  // because on this breakpoint ".the-content-decor" is set to "display: none" 
  if (window.matchMedia("(max-width: 960px)").matches) {
    return;
  }
  var interviewTitle = document.querySelector('.interview-content > .interview-title');
  var interviewContent = document.querySelector('.interview-content > .interview-title + .wp-block-group');
  if (!interviewTitle) {
    return;
  }
  if (!interviewContent) {
    return;
  }
  var interviewContentDecor = document.querySelector('.interview-content-wrapper .the-content-decor');
  if (!interviewContentDecor) {
    return;
  }
  var decoreTextNodeLeft = interviewContentDecor.querySelector('.decor-left span');
  var decoreTextNodeRight = interviewContentDecor.querySelector('.decor-right span');
  if (!decoreTextNodeLeft || !decoreTextNodeRight || !decoreTextNodeLeft.innerText || !decoreTextNodeRight.innerText) {
    return;
  }
  var interviewTitleHeight = interviewTitle.getBoundingClientRect().height;
  var interviewContentHeight = interviewContent.getBoundingClientRect().height;
  var interviewContentPaddingTop = parseInt(getComputedStyle(interviewContent).getPropertyValue('padding-top'));
  var interviewContentPaddingBottom = parseInt(getComputedStyle(interviewContent).getPropertyValue('padding-bottom'));
  var decoreTextNodeHeight = decoreTextNodeLeft.getBoundingClientRect().height;

  // if decoreTextNodeHeight == 0 then don't do anything 
  // otherwise we'll get division by zero further in this function (*1)
  if (!decoreTextNodeHeight) {
    return;
  }

  // calculating interview text height without container paddings
  var totalContentHeight = interviewContentHeight - interviewContentPaddingTop - interviewContentPaddingBottom;
  var decoreText = decoreTextNodeLeft.innerText.trim();
  interviewContentDecor.style.top = interviewTitleHeight + interviewContentPaddingTop + 'px';

  // times we need to add decorTextNode to decor to fit interview content height
  // (*1)
  var times = Math.floor(totalContentHeight / decoreTextNodeHeight);

  // height left for extra decor text 
  var remainingHeight = 0;
  // height of one text chunk
  var decoreTextNodeInitialHeight = decoreTextNodeHeight;
  while (times > 1) {
    decoreTextNodeLeft.innerText += ' / ' + decoreText; //(*2)

    // (*2)
    // ' / ' also adds some pixels to decor total height, so
    // we need to check if there's enough space for one more decor text chunk

    remainingHeight = totalContentHeight - decoreTextNodeLeft.getBoundingClientRect().height;
    if (remainingHeight < decoreTextNodeInitialHeight) {
      break;
    }
    times--;
  }
  decoreTextNodeLeft.innerText = decoreTextNodeLeft.innerText.trim();
  decoreTextNodeRight.innerText = decoreTextNodeLeft.innerText;
}
var redirectPopup = function redirectPopup() {
  redirectFromMenuItem();
  redirectFromContent();
};
var redirectFromMenuItem = function redirectFromMenuItem() {
  var redirectItems = document.querySelectorAll('[data-lang-flag]');
  var popup = document.querySelector('#redirect-popup');
  if (!redirectItems.length) {
    return;
  }
  if (!popup) {
    return;
  }
  redirectItems.forEach(function (item) {
    item.addEventListener('click', function (event) {
      var elementWithRedirect = event.target.closest('[data-lang-flag]');
      if (elementWithRedirect && elementWithRedirect.dataset.langFlag === 'true') {
        event.preventDefault();
        var langTargetBtn = popup.querySelector('[data-lang-target]');
        if (!langTargetBtn) {
          return;
        }
        langTargetBtn.setAttribute('href', elementWithRedirect.dataset.langRedirectUrl);
        popup.classList.add('popup-visible');
        document.querySelector('body').classList.add('scroll-lock');
      }
    });
  });
};
var redirectFromContent = function redirectFromContent() {};
var closePopupHandler = function closePopupHandler() {
  var closeBtns = document.querySelectorAll('[data-close-popup-btn]');
  if (!closeBtns.length) {
    return;
  }
  closeBtns.forEach(function (btn) {
    btn.addEventListener('click', function (event) {
      var popup = event.target.closest('.popup-wrapper');
      if (popup) {
        popup.classList.remove('popup-visible');
      }
      document.querySelector('body').classList.remove('scroll-lock');
    });
  });
};
closePopupHandler();

// ---------------  POLYFILLS --------------------// 

// matches

(function () {
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.webkitMatchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector;
  }
})();

// closest

(function () {
  if (!Element.prototype.closest) {
    Element.prototype.closest = function (css) {
      var node = this;
      while (node) {
        if (node.matches(css)) return node;else node = node.parentElement;
      }
      return null;
    };
  }
})();
window.addEventListener('load', function () {
  loader();
  header();
  homeHeroAnimation();
  aboutHeroAnimation();
  slidersHandler();
  reviewsGalleryHandler();
  bulbAnimation();
  btnAnimation();
  buttonAccordionHandler();
  videoHandler();
  youtubeVideoHandler();
  fileUploadBtnUI();
  scrollToTop();
  historyBack();
  toggleBookContents();
  toggleTeamContents();
  formSubmit();
  formSubmitPopup();
  postContentDecor();
  vacancyContentDecor();
  setPolicyUrl();
  onScrollPopupHandler();
  redirectPopup();
  phoneInputMaskPL();
  telephoneMask();
  var $page = $('html, body');
  $('a[href*="#"]').click(function () {
    $page.animate({
      scrollTop: $($.attr(this, 'href')).offset().top
    }, 400);
    return false;
  });
});
function scrollToTop() {
  totopBtnToggle();
  $(".totop").click(function () {
    $("html, body").animate({
      scrollTop: 0
    }, "slow");
    return false;
  });
}
function totopBtnToggle() {
  var totopBtn = document.querySelector('.totop');
  if (totopBtn) {
    window.addEventListener('scroll', function () {
      if (window.scrollY > 500) {
        totopBtn.classList.add('visible');
      } else {
        totopBtn.classList.remove('visible');
      }
    });
  }
  ;
}
$(document).ready(function () {
  $('[data-toggle="content_button"]').click(function () {
    var accordion_button = $(this);
    var content = accordion_button.next(".accordion_content");
    var parent = accordion_button.parent().parent().parent();
    var contentRight = parent.find(".accordion_right");
    var windowWidth = $(window).width();
    if (contentRight.find(".accordion_content").html() === content.html() || content.is(":visible")) {
      // accordion_button.removeClass("active");
      if (windowWidth <= 1100) {
        accordion_button.removeClass("active");
        content.slideToggle();

        // parent.find('[data-toggle="content_button"]').removeClass("active");
        // accordion_button.addClass("active");
        // content.slideToggle();
      }
      // if (windowWidth > 1100) {
      //     contentRight.fadeOut(function() {
      //         contentRight.find(".accordion_content").hide();
      //         contentRight.empty();
      //     });
      // }
    } else {
      parent.find('[data-toggle="content_button"]').removeClass("active");
      // $('[data-toggle="content_button"]').removeClass("active");
      accordion_button.addClass("active");
      if (windowWidth <= 1100) {
        console.log('ne content', parent.find('[data-toggle="content"]'));
        parent.find('[data-toggle="content"]').not(content).slideUp();
        content.slideToggle();
        console.log('content', content);
      }
      if (windowWidth > 1100 && contentRight.html() !== content.html()) {
        contentRight.fadeOut(function () {
          contentRight.html(content.clone(true));
          contentRight.find(".accordion_content").show();
          contentRight.fadeIn();
        });
      }
    }
  });

  // let firstButton = $('[data-toggle="content_button"]:first');
  // if (firstButton.length > 0) {
  //     firstButton.click();
  // }

  var accordion1 = $('#accordion_1 [data-toggle="content_button"]:first');
  var accordion2 = $('#accordion_2 [data-toggle="content_button"]:first');
  if (accordion1.length > 0) {
    accordion1.click();
    console.log('click 1');
  }
  if (accordion2.length > 0) {
    accordion2.click();
    console.log('click 2');
  }
});
siteCreationSlider();
function siteCreationSlider() {
  var siteCreationSlider = document.querySelector("#stages_wrapper");
  var noSlider = document.querySelector("#promotion-instagram #stages_wrapper");
  var marketingSlider = document.querySelector("#marketing_stages_wrapper");
  var settings = {
    arrows: false,
    dots: false,
    slidesToShow: 1,
    infinite: true,
    adaptiveHeight: true
  };
  if (window.innerWidth <= 576) {
    $("#stages_wrapper .hide_slide").remove();
    $("#marketing_stages_wrapper .hide_slide").remove();
    var slider;
    if (siteCreationSlider) {
      $(siteCreationSlider).slick(settings);
      slider = $(siteCreationSlider);
      $(noSlider).slick('unslick');
    }
    if (marketingSlider) {
      $(marketingSlider).slick(settings);
      slider = $(marketingSlider);
    }
    $("[data-slider-site-creation] .slide-prev").click(function (e) {
      slider.slick("slickPrev");
    });
    $("[data-slider-site-creation] .slide-next").click(function (e) {
      slider.slick("slickNext");
    });
  }
}

// email marketing page grid-accordion

$('.grid-accordion').on('click', '.accordion-question', function () {
  console.log('df');
  var accordionItem = $(this).closest('.accordion-item');
  $('.grid-accordion .accordion-content').not(accordionItem.find('.accordion-content')).slideUp();
  $('.grid-accordion .accordion-item').not(accordionItem).removeClass('active');
  accordionItem.find('.accordion-content').slideToggle(300);
  accordionItem.toggleClass('active');
  // accordionItem.slideToggle(300)
});
// ---------------  SLIDERS --------------------
function slidersHandler() {
  casesSlider();
  casesPolandSlider();
  reviewsSlider();
  teamSlider();
  marquee();
  articlesSlider();
  consultSlider();
  mentionsSlider();
  partnersSlider();
  auditResultsSlider();
  auditPrinciplesSlider();
}
function casesSlider() {
  var _settings;
  var casesSlider = document.querySelector("[data-cases-slider]");
  var settings = (_settings = {
    arrows: false,
    dots: true,
    slidesToShow: 1,
    infinite: true
  }, _defineProperty(_settings, "arrows", false), _defineProperty(_settings, "adaptiveHeight", true), _settings);
  if (window.innerWidth <= 768) {
    if (casesSlider) {
      $(casesSlider).slick(settings);
    }
  }
}
function casesPolandSlider() {
  var casesSlider = document.querySelector("[data-cases-slider-pl]");
  if (!casesSlider) {
    return;
  }
  var settings = {
    arrows: false,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    adaptiveHeight: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        dots: true
      }
    }]
  };
  if (casesSlider) {
    $(casesSlider).slick(settings);
  }
  $(".slider-nav-pl .slide-prev").click(function (e) {
    $(casesSlider).slick("slickPrev");
  });
  $(".slider-nav-pl .slide-next").click(function (e) {
    $(casesSlider).slick("slickNext");
  });
}
function mentionsSlider() {
  var mentionsSlider = document.querySelector("[data-mentions-slider]");
  $(mentionsSlider).slick({
    slidesToShow: 3,
    initialSlide: 1,
    infinite: true,
    arrows: false,
    responsive: [{
      breakpoint: 880,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 769,
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 1
      }
    }]
  });
  $(".mentions-nav .slide-prev").click(function (e) {
    $(mentionsSlider).slick("slickPrev");
  });
  $(".mentions-nav .slide-next").click(function (e) {
    $(mentionsSlider).slick("slickNext");
  });
}
function reviewsSlider() {
  var slider = document.querySelector("[data-reviews-slider]");
  var slickOptions = {
    centerMode: true,
    slidesToShow: 3,
    initialSlide: 1,
    infinite: true,
    arrows: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        arrows: false,
        dots: true,
        centerMode: false,
        slidesToShow: 1
      }
    }]
  };
  var mfpOptions = {
    type: 'image',
    delegate: 'a:not(.slick-cloned)',
    gallery: {
      enabled: true
    },
    callbacks: {
      open: function open() {
        var current = $(slider).slick('slickCurrentSlide');
        $(slider).magnificPopup('goTo', current);
      },
      beforeClose: function beforeClose() {
        $(slider).slick('slickGoTo', parseInt(this.index));
      }
    }
  };
  $(slider).slick(slickOptions);
  //$(slider).magnificPopup(mfpOptions);

  $(".reviews-nav .slide-prev").click(function (e) {
    $(slider).slick("slickPrev");
  });
  $(".reviews-nav .slide-next").click(function (e) {
    $(slider).slick("slickNext");
  });
}
function teamSlider() {
  var _settings2;
  var teamSlider = document.querySelector("[data-team-slider]");
  var settings = (_settings2 = {
    arrows: false,
    dots: true,
    slidesToShow: 1,
    infinite: true
  }, _defineProperty(_settings2, "arrows", false), _defineProperty(_settings2, "adaptiveHeight", true), _settings2);
  if (window.innerWidth <= 768) {
    if (teamSlider) {
      $(teamSlider).slick(settings);
    }
  }
}
function marquee() {
  var marqueeElementsDt = document.querySelectorAll("[data-marquee-dt]");
  var marqueeElementsMob = document.querySelectorAll("[data-marquee-mob]");
  if (marqueeElementsDt.length !== 0) {
    marqueeElementsDt.forEach(function (el) {
      return animateMarquee(el, 30000);
    });
  }
  if (marqueeElementsMob.length !== 0) {
    marqueeElementsMob.forEach(function (el) {
      return animateMarquee(el, 30000);
    });
  }
}
function animateMarquee(el, duration) {
  var innerEl = el.querySelector(".marquee__inner");
  var innerWidth = innerEl.offsetWidth;
  var cloneEl = innerEl.cloneNode(true);
  el.appendChild(cloneEl);
  var start = performance.now();
  var progress;
  var translateX;
  requestAnimationFrame(function step(now) {
    progress = (now - start) / duration;
    if (progress > 1) {
      progress %= 1;
      start = now;
    }
    translateX = innerWidth * progress;
    innerEl.style.transform = "translate3d(-".concat(translateX, "px, 0 , 0)");
    cloneEl.style.transform = "translate3d(-".concat(translateX, "px, 0 , 0)");
    requestAnimationFrame(step);
  });
}
function articlesSlider() {
  var articlesSlider = document.querySelector("[data-articles-slider]");
  $(articlesSlider).slick({
    slidesToShow: 3,
    initialSlide: 1,
    infinite: true,
    arrows: false,
    responsive: [{
      breakpoint: 880,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 769,
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 1
      }
    }, {
      breakpoint: 580,
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 1
      }
    }]
  });
  $(".articles-nav .slide-prev").click(function (e) {
    $(articlesSlider).slick("slickPrev");
  });
  $(".articles-nav .slide-next").click(function (e) {
    $(articlesSlider).slick("slickNext");
  });
}
function consultSlider() {
  var consultSlider = document.querySelector("[data-consult-slider]");
  if (consultSlider) {
    var slides = document.querySelector("[data-consult-slider] .slide");
    var currentSlideNumber = document.querySelector("[data-consult-nav] .slide-current");
    var totalSlidesNumber = document.querySelector("[data-consult-nav] .slide-total");
    var slideNumberElements = document.querySelectorAll("[data-consult-slider] .slide-number");
    if (slides.length !== 0) {
      slideNumberElements.forEach(function (el, index) {
        el.innerText = "0".concat(index + 1);
      });
    }
    $(consultSlider).slick({
      slidesToShow: 1,
      infinite: true,
      arrows: false,
      adaptiveHeight: true
    });
    $("[data-consult-nav] .slide-prev").click(function (e) {
      $(consultSlider).slick("slickPrev");
    });
    $("[data-consult-nav] .slide-next").click(function (e) {
      $(consultSlider).slick("slickNext");
    });
    $(consultSlider).on("init reInit afterChange", function (event, slick, currentSlide, nextSlide) {
      //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
      var i = (currentSlide ? currentSlide : 0) + 1;
      currentSlideNumber.innerText = "0".concat(i, " ");
      totalSlidesNumber.innerText = " / 0".concat(slick.slideCount);
    });
  }
}
function partnersSlider() {
  $(function () {
    $('.p-popup-modal').magnificPopup({
      type: 'inline',
      preloader: false,
      modal: false,
      showCloseBtn: true
    });
    $(document).on('click', '.popup-modal-dismiss', function (e) {
      e.preventDefault();
      $.magnificPopup.close();
    });
  });
}
function auditResultsSlider() {
  var _settings3;
  if (!window.matchMedia("(max-width: 480px)").matches) {
    return;
  }
  var slider = document.querySelector('[data-audit-results-slider]');
  if (!slider) {
    return;
  }
  var settings = (_settings3 = {
    arrows: false,
    dots: true,
    slidesToShow: 1,
    infinite: true
  }, _defineProperty(_settings3, "arrows", false), _defineProperty(_settings3, "adaptiveHeight", true), _settings3);
  $(slider).slick(settings);
}
function auditPrinciplesSlider() {
  var _settings4;
  if (!window.matchMedia("(max-width: 768px)").matches) {
    return;
  }
  var slider = document.querySelector('[data-principles-slider]');
  if (!slider) {
    return;
  }
  var settings = (_settings4 = {
    arrows: false,
    dots: true,
    slidesToShow: 1,
    infinite: true
  }, _defineProperty(_settings4, "arrows", false), _defineProperty(_settings4, "adaptiveHeight", true), _settings4);
  $(slider).slick(settings);
}
function telephoneMask() {
  var telephoneInputs = document.querySelectorAll('input[type="tel"]');
  if (telephoneInputs.length === 0) {
    return;
  }
  var maskOptions = {
    initialCountry: "auto",
    excludeCountries: ["by", "ru"],
    separateDialCode: true,
    customPlaceholder: function customPlaceholder(selectedCountryPlaceholder, selectedCountryData) {
      if (selectedCountryData.dialCode === '380') {
        selectedCountryPlaceholder = '50 123 4567';
      }
      return selectedCountryPlaceholder;
    },
    geoIpLookup: function geoIpLookup(callback) {
      fetch("https://ipapi.co/json").then(function (res) {
        return res.json();
      }).then(function (data) {
        return callback(data.country_code);
      })["catch"](function () {
        return callback("us");
      });
    },
    utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js"
  };
  telephoneInputs.forEach(function (telephoneInput) {
    var iti = intlTelInput(telephoneInput, maskOptions);
    var phoneCompleteInput = telephoneInput.closest('form').querySelector('input[name="phone-complete"]');
    var countryInput = telephoneInput.closest('form').querySelector('input[name="country"]');
    telephoneInput.addEventListener('input', function () {
      if (phoneCompleteInput) {
        phoneCompleteInput.value = iti.getNumber();
      }
      if (countryInput) {
        countryInput.value = iti.getSelectedCountryData().name;
      }
    });
    telephoneInput.addEventListener('countrychange', function () {
      if (phoneCompleteInput) {
        phoneCompleteInput.value = iti.getNumber();
      }
      if (countryInput) {
        countryInput.value = iti.getSelectedCountryData().name;
      }
    });
  });
}
function toggleBookContents() {
  var btnToggle = document.querySelector('[data-book-content-toggle]');
  var bookContents = document.querySelector('.book-contents-wrapper ul');
  if (!btnToggle || !bookContents) {
    return;
  }
  var toggleMore = btnToggle.querySelector('[data-toggle-more]');
  var toggleLess = btnToggle.querySelector('[data-toggle-less]');
  var yPosMarker = document.querySelector('[data-y-pos]');
  btnToggle.addEventListener('click', function (event) {
    if (!yPosMarker.dataset.yPos) {
      yPosMarker.dataset.yPos = window.scrollY;
    } else {
      window.scrollTo(0, yPosMarker.dataset.yPos);
    }
    bookContents.classList.toggle('expanded');
    toggleMore.classList.toggle('hidden');
    toggleLess.classList.toggle('hidden');
  });
}
function toggleTeamContents() {
  var teamContentToggle = document.querySelector('[data-team-toggle]');
  var teamCards = document.querySelectorAll('.team-list__part-2 .team-item:nth-child(n + 5)');
  if (!teamContentToggle) {
    return;
  }
  if (teamCards.length === 0) {
    return;
  }
  teamContentToggle.addEventListener('click', function (event) {
    teamCards.forEach(function (card) {
      card.classList.add('visible');
    });
    var btnWrapper = event.target.closest('.team-list__more');
    if (btnWrapper) {
      btnWrapper.style.display = 'none';
    }
  });
}
function vacancyContentDecor() {
  // otherwise page causes immense memory leak!!!
  // on this breakpoint ".the-content-decor" is set to "display: none" 
  if (window.matchMedia("(max-width: 960px)").matches) {
    return;
  }
  var decors = document.querySelectorAll('.vacancies-list .the-content-decor');
  if (decors.length === 0) {
    return;
  }
  decors.forEach(function (decor) {
    return decorHandler(decor);
  });
}
function decorHandler(decor) {
  var postWrapper = decor.closest('.single-post-wrapper');
  if (!postWrapper) {
    return;
  }
  var postContent = postWrapper.querySelector('.single-post-content');
  if (!postContent) {
    return;
  }
  var decoreTextNodeLeft = decor.querySelector('.decor-left span');
  var decoreTextNodeRight = decor.querySelector('.decor-right span');
  if (!decoreTextNodeLeft || !decoreTextNodeRight || !decoreTextNodeLeft.innerText) {
    return;
  }
  var decoreTextNodeHeight = decoreTextNodeLeft.getBoundingClientRect().height;
  var decoreText = decoreTextNodeLeft.innerText.trim();

  // times we need to add decorTextNode to decor to fit vacancy content height
  var times = Math.floor(postContent.scrollHeight / decoreTextNodeHeight);
  while (times > 1) {
    decoreTextNodeLeft.innerText += ' / ' + decoreText;
    times--;
  }
  decoreTextNodeLeft.innerText = decoreTextNodeLeft.innerText.trim();
  decoreTextNodeRight.innerText = decoreTextNodeLeft.innerText;
}
function videoHandler() {
  // TODO: 
  // 
  // handle video pause/stop when out of the viewport

  $('[data-popup-toggle]').magnificPopup({
    type: 'inline',
    fixedContentPos: false,
    fixedBgPos: true,
    overflowY: 'auto',
    closeBtnInside: true,
    preloader: false,
    midClick: true,
    removalDelay: 300,
    mainClass: 'my-mfp-zoom-in'
  });
}
function youtubeVideoHandler() {
  var videoBtn = document.querySelector('.video-section .video-button');
  var video = document.querySelector('.video-section [data-video]');
  if (!videoBtn || !video) {
    return;
  }
  videoBtn.addEventListener('click', function (event) {
    videoBtn.style.display = 'none';
    video.play();
    video.setAttribute('controls', 'controls');
  });
}